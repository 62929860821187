import * as React from 'react';
import * as styles from './article.module.scss';
import { SEO } from '../../components/seo/seo';
import { MainTemplate } from '../main/main';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import { PageHeadline } from '../../components/page-headline/page-headline';

interface IProps {
  location: Location;
  translationsKey: string;
  billboardImage: React.ReactNode;
}
export default function ArticleTemplate({
  location,
  translationsKey,
  billboardImage,
}: IProps) {
  const intl = useIntl();

  return (
    <MainTemplate>
      <SEO
        location={location}
        title={intl.formatMessage({
          id: `blog.articles_data.${translationsKey}.headline`,
        })}
        description={intl.formatMessage({
          id: `blog.articles_data.${translationsKey}.content`,
        })}
      />

      <div className={`${styles.wrapper} container`}>
        <PageHeadline>
          <FormattedMessage
            id={`blog.articles_data.${translationsKey}.headline`}
          />
        </PageHeadline>
        <p>
          <FormattedMessage
            id={`blog.articles_data.${translationsKey}.overview`}
          />
        </p>
        <div className={styles.billboardImage}>{billboardImage}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: `blog.articles_data.${translationsKey}.content`,
            }),
          }}
        />
      </div>
    </MainTemplate>
  );
}
