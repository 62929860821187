import * as React from 'react';
import { PageProps } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import ArticleTemplate from '../../templates/article/article';
import { StaticImage } from 'gatsby-plugin-image';

const TopInterestingFactsAboutCardboardBoxesPage = ({
  location,
}: PageProps) => {
  const intl = useIntl();
  const translationsKey = 'interesting_facts';

  const billboardImage = (
    <StaticImage
      src={`../../images/blog/interesting-facts.jpg`}
      alt={intl.formatMessage({
        id: `blog.articles_data.${translationsKey}.headline`,
      })}
      loading="lazy"
      placeholder="blurred"
      layout="fullWidth"
      aspectRatio={3}
      formats={['auto', 'webp', 'avif']}
    />
  );

  return (
    <ArticleTemplate
      location={location}
      translationsKey={translationsKey}
      billboardImage={billboardImage}
    />
  );
};

export default TopInterestingFactsAboutCardboardBoxesPage;
